/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CoreApiConfiguration as __Configuration } from '../core-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ShutdownEntry } from '../models/shutdown-entry';
import { ScanContainerResponse } from '../models/scan-container-response';
import { ScanContainerEntry } from '../models/scan-container-entry';
import { WorkCenterState } from '../models/work-center-state';
import { WorkCenter } from '../models/work-center';
import { WorkCenterData } from '../models/work-center-data';
import { WorkCenterDetail } from '../models/work-center-detail';
import { WorkCenterExternalInfo } from '../models/work-center-external-info';
import { WorkCenterMachineData } from '../models/work-center-machine-data';
import { ShutdownReason } from '../models/shutdown-reason';
import { ProductionPeriod } from '../models/production-period';
import { ProductionPeriodDetailData } from '../models/production-period-detail-data';
import { WasteReason } from '../models/waste-reason';
import { ShutdownInfo } from '../models/shutdown-info';
import { CounterInfo } from '../models/counter-info';
import { QuantityPool } from '../models/quantity-pool';
import { WorkCenterQaHistoryParameter } from '../models/work-center-qa-history-parameter';
import { AvailableKpisResult } from '../models/available-kpis-result';
import { KpiValue } from '../models/kpi-value';
import { GetKpisEntry } from '../models/get-kpis-entry';
import { WasteAssignmentInTime } from '../models/waste-assignment-in-time';
@Injectable({
  providedIn: 'root'
})
class WorkCenterService extends __BaseService {
  static readonly shutdownPath = '/api/v1/WorkCenter/{workCenterId}/Shutdown';
  static readonly startupPath = '/api/v1/WorkCenter/{workCenterId}/Startup';
  static readonly scanContainerPath = '/api/v1/WorkCenter/{workCenterId}/Scan';
  static readonly getCurrentStatePath = '/api/v1/WorkCenter/{workCenterId}/CurrentState';
  static readonly getWorkCenterPath = '/api/v1/WorkCenter/{workCenterId}';
  static readonly getWorkCentersPath = '/api/v1/WorkCenter';
  static readonly getWorkCenterListPath = '/api/v1/WorkCenter';
  static readonly getWorkCenterDetailPath = '/api/v1/WorkCenter/{workCenterId}/detail';
  static readonly getWorkCenterByExternalIdPath = '/api/v1/WorkCenter/GetWorkCenterByExternalId/{externalWorkCenterId}';
  static readonly getWorkCenterInfoByExternalIdPath = '/api/v1/WorkCenter/GetWorkCenterInfoByExternalId/{externalWorkCenterId}';
  static readonly getWorkCenterMachineDataListPath = '/api/v1/WorkCenter/WorkCenterMachineDataList';
  static readonly getShutdownReasonsPath = '/api/v1/WorkCenter/ShutdownReasons';
  static readonly getOpenPeriodsPath = '/api/v1/WorkCenter/{workCenterId}/GetOpenPeriods';
  static readonly getPeriodsPath = '/api/v1/WorkCenter/{workCenterId}/GetPeriods';
  static readonly getWasteReasonsPath = '/api/v1/WorkCenter/{workCenterId}/wasteReasons';
  static readonly getShutdownInfoPath = '/api/v1/WorkCenter/{workCenterId}/ShutdownInfo';
  static readonly workCenterGetCurrentCounterPath = '/api/v1/WorkCenter/{workCenterId}/CurrentCounter';
  static readonly getQuantityPoolPath = '/api/v1/WorkCenter/{workCenterId}/QuantityPool';
  static readonly getQaHistoryParameterValuePath = '/api/v1/WorkCenter/{workCenterId}/QaHistoryParameterValue';
  static readonly getAvailableKpiIdentifiersPath = '/api/v1/WorkCenter/{workCenterId}/AvailableKpis';
  static readonly getKpisPath = '/api/v1/WorkCenter/{workCenterId}/Kpis';
  static readonly getWasteAssignmentsPath = '/api/v1/WorkCenter/{workCenterId}/wasteAssignments';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param params The `WorkCenterService.ShutdownParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `shutdownEntry`:
   */
  shutdownResponse(params: WorkCenterService.ShutdownParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.shutdownEntry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/WorkCenter/${encodeURIComponent(String(params.workCenterId))}/Shutdown`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `WorkCenterService.ShutdownParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `shutdownEntry`:
   */
  shutdown(params: WorkCenterService.ShutdownParams): __Observable<null> {
    return this.shutdownResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param workCenterId undefined
   */
  startupResponse(workCenterId: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/WorkCenter/${encodeURIComponent(String(workCenterId))}/Startup`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param workCenterId undefined
   */
  startup(workCenterId: number): __Observable<null> {
    return this.startupResponse(workCenterId).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `WorkCenterService.ScanContainerParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `entry`:
   */
  scanContainerResponse(params: WorkCenterService.ScanContainerParams): __Observable<__StrictHttpResponse<ScanContainerResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/WorkCenter/${encodeURIComponent(String(params.workCenterId))}/Scan`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ScanContainerResponse>;
      })
    );
  }
  /**
   * @param params The `WorkCenterService.ScanContainerParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `entry`:
   */
  scanContainer(params: WorkCenterService.ScanContainerParams): __Observable<ScanContainerResponse> {
    return this.scanContainerResponse(params).pipe(__map((_r) => _r.body as ScanContainerResponse));
  }

  /**
   * @param workCenterId undefined
   */
  getCurrentStateResponse(workCenterId: number): __Observable<__StrictHttpResponse<WorkCenterState>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/WorkCenter/${encodeURIComponent(String(workCenterId))}/CurrentState`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<WorkCenterState>;
      })
    );
  }
  /**
   * @param workCenterId undefined
   */
  getCurrentState(workCenterId: number): __Observable<WorkCenterState> {
    return this.getCurrentStateResponse(workCenterId).pipe(__map((_r) => _r.body as WorkCenterState));
  }

  /**
   * @param workCenterId undefined
   */
  getWorkCenterResponse(workCenterId: number): __Observable<__StrictHttpResponse<WorkCenter>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/v1/WorkCenter/${encodeURIComponent(String(workCenterId))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<WorkCenter>;
      })
    );
  }
  /**
   * @param workCenterId undefined
   */
  getWorkCenter(workCenterId: number): __Observable<WorkCenter> {
    return this.getWorkCenterResponse(workCenterId).pipe(__map((_r) => _r.body as WorkCenter));
  }

  /**
   * @param workCenterIds undefined
   */
  getWorkCentersResponse(workCenterIds: Array<number>): __Observable<__StrictHttpResponse<Array<WorkCenter>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = workCenterIds;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/v1/WorkCenter`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<WorkCenter>>;
      })
    );
  }
  /**
   * @param workCenterIds undefined
   */
  getWorkCenters(workCenterIds: Array<number>): __Observable<Array<WorkCenter>> {
    return this.getWorkCentersResponse(workCenterIds).pipe(__map((_r) => _r.body as Array<WorkCenter>));
  }
  getWorkCenterListResponse(): __Observable<__StrictHttpResponse<Array<WorkCenterData>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/v1/WorkCenter`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<WorkCenterData>>;
      })
    );
  }
  getWorkCenterList(): __Observable<Array<WorkCenterData>> {
    return this.getWorkCenterListResponse().pipe(__map((_r) => _r.body as Array<WorkCenterData>));
  }

  /**
   * @param workCenterId undefined
   */
  getWorkCenterDetailResponse(workCenterId: number): __Observable<__StrictHttpResponse<WorkCenterDetail>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/v1/WorkCenter/${encodeURIComponent(String(workCenterId))}/detail`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<WorkCenterDetail>;
      })
    );
  }
  /**
   * @param workCenterId undefined
   */
  getWorkCenterDetail(workCenterId: number): __Observable<WorkCenterDetail> {
    return this.getWorkCenterDetailResponse(workCenterId).pipe(__map((_r) => _r.body as WorkCenterDetail));
  }

  /**
   * @param externalWorkCenterId undefined
   */
  getWorkCenterByExternalIdResponse(externalWorkCenterId: string): __Observable<__StrictHttpResponse<WorkCenter>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/WorkCenter/GetWorkCenterByExternalId/${encodeURIComponent(String(externalWorkCenterId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<WorkCenter>;
      })
    );
  }
  /**
   * @param externalWorkCenterId undefined
   */
  getWorkCenterByExternalId(externalWorkCenterId: string): __Observable<WorkCenter> {
    return this.getWorkCenterByExternalIdResponse(externalWorkCenterId).pipe(__map((_r) => _r.body as WorkCenter));
  }

  /**
   * @param externalWorkCenterId undefined
   */
  getWorkCenterInfoByExternalIdResponse(externalWorkCenterId: string): __Observable<__StrictHttpResponse<WorkCenterExternalInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/WorkCenter/GetWorkCenterInfoByExternalId/${encodeURIComponent(String(externalWorkCenterId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<WorkCenterExternalInfo>;
      })
    );
  }
  /**
   * @param externalWorkCenterId undefined
   */
  getWorkCenterInfoByExternalId(externalWorkCenterId: string): __Observable<WorkCenterExternalInfo> {
    return this.getWorkCenterInfoByExternalIdResponse(externalWorkCenterId).pipe(__map((_r) => _r.body as WorkCenterExternalInfo));
  }
  getWorkCenterMachineDataListResponse(): __Observable<__StrictHttpResponse<Array<WorkCenterMachineData>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/v1/WorkCenter/WorkCenterMachineDataList`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<WorkCenterMachineData>>;
      })
    );
  }
  getWorkCenterMachineDataList(): __Observable<Array<WorkCenterMachineData>> {
    return this.getWorkCenterMachineDataListResponse().pipe(__map((_r) => _r.body as Array<WorkCenterMachineData>));
  }
  getShutdownReasonsResponse(): __Observable<__StrictHttpResponse<Array<ShutdownReason>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/v1/WorkCenter/ShutdownReasons`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ShutdownReason>>;
      })
    );
  }
  getShutdownReasons(): __Observable<Array<ShutdownReason>> {
    return this.getShutdownReasonsResponse().pipe(__map((_r) => _r.body as Array<ShutdownReason>));
  }

  /**
   * @param workCenterId undefined
   */
  getOpenPeriodsResponse(workCenterId: number): __Observable<__StrictHttpResponse<Array<ProductionPeriod>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/WorkCenter/${encodeURIComponent(String(workCenterId))}/GetOpenPeriods`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ProductionPeriod>>;
      })
    );
  }
  /**
   * @param workCenterId undefined
   */
  getOpenPeriods(workCenterId: number): __Observable<Array<ProductionPeriod>> {
    return this.getOpenPeriodsResponse(workCenterId).pipe(__map((_r) => _r.body as Array<ProductionPeriod>));
  }

  /**
   * @param params The `WorkCenterService.GetPeriodsParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `periodStart`:
   *
   * - `periodEnd`:
   */
  getPeriodsResponse(params: WorkCenterService.GetPeriodsParams): __Observable<__StrictHttpResponse<Array<ProductionPeriodDetailData>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.periodStart != null) __params = __params.set('periodStart', params.periodStart.toString());
    if (params.periodEnd != null) __params = __params.set('periodEnd', params.periodEnd.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/WorkCenter/${encodeURIComponent(String(params.workCenterId))}/GetPeriods`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ProductionPeriodDetailData>>;
      })
    );
  }
  /**
   * @param params The `WorkCenterService.GetPeriodsParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `periodStart`:
   *
   * - `periodEnd`:
   */
  getPeriods(params: WorkCenterService.GetPeriodsParams): __Observable<Array<ProductionPeriodDetailData>> {
    return this.getPeriodsResponse(params).pipe(__map((_r) => _r.body as Array<ProductionPeriodDetailData>));
  }

  /**
   * @param workCenterId undefined
   */
  getWasteReasonsResponse(workCenterId: number): __Observable<__StrictHttpResponse<Array<WasteReason>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/WorkCenter/${encodeURIComponent(String(workCenterId))}/wasteReasons`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<WasteReason>>;
      })
    );
  }
  /**
   * @param workCenterId undefined
   */
  getWasteReasons(workCenterId: number): __Observable<Array<WasteReason>> {
    return this.getWasteReasonsResponse(workCenterId).pipe(__map((_r) => _r.body as Array<WasteReason>));
  }

  /**
   * @param workCenterId undefined
   */
  getShutdownInfoResponse(workCenterId: number): __Observable<__StrictHttpResponse<ShutdownInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/WorkCenter/${encodeURIComponent(String(workCenterId))}/ShutdownInfo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ShutdownInfo>;
      })
    );
  }
  /**
   * @param workCenterId undefined
   */
  getShutdownInfo(workCenterId: number): __Observable<ShutdownInfo> {
    return this.getShutdownInfoResponse(workCenterId).pipe(__map((_r) => _r.body as ShutdownInfo));
  }

  /**
   * @param workCenterId undefined
   */
  workCenterGetCurrentCounterResponse(workCenterId: number): __Observable<__StrictHttpResponse<CounterInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/WorkCenter/${encodeURIComponent(String(workCenterId))}/CurrentCounter`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CounterInfo>;
      })
    );
  }
  /**
   * @param workCenterId undefined
   */
  workCenterGetCurrentCounter(workCenterId: number): __Observable<CounterInfo> {
    return this.workCenterGetCurrentCounterResponse(workCenterId).pipe(__map((_r) => _r.body as CounterInfo));
  }

  /**
   * @param workCenterId undefined
   */
  getQuantityPoolResponse(workCenterId: number): __Observable<__StrictHttpResponse<QuantityPool>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/WorkCenter/${encodeURIComponent(String(workCenterId))}/QuantityPool`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<QuantityPool>;
      })
    );
  }
  /**
   * @param workCenterId undefined
   */
  getQuantityPool(workCenterId: number): __Observable<QuantityPool> {
    return this.getQuantityPoolResponse(workCenterId).pipe(__map((_r) => _r.body as QuantityPool));
  }

  /**
   * @param workCenterId undefined
   */
  getQaHistoryParameterValueResponse(workCenterId: number): __Observable<__StrictHttpResponse<WorkCenterQaHistoryParameter>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/WorkCenter/${encodeURIComponent(String(workCenterId))}/QaHistoryParameterValue`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<WorkCenterQaHistoryParameter>;
      })
    );
  }
  /**
   * @param workCenterId undefined
   */
  getQaHistoryParameterValue(workCenterId: number): __Observable<WorkCenterQaHistoryParameter> {
    return this.getQaHistoryParameterValueResponse(workCenterId).pipe(__map((_r) => _r.body as WorkCenterQaHistoryParameter));
  }

  /**
   * @param workCenterId undefined
   */
  getAvailableKpiIdentifiersResponse(workCenterId: number): __Observable<__StrictHttpResponse<AvailableKpisResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/WorkCenter/${encodeURIComponent(String(workCenterId))}/AvailableKpis`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AvailableKpisResult>;
      })
    );
  }
  /**
   * @param workCenterId undefined
   */
  getAvailableKpiIdentifiers(workCenterId: number): __Observable<AvailableKpisResult> {
    return this.getAvailableKpiIdentifiersResponse(workCenterId).pipe(__map((_r) => _r.body as AvailableKpisResult));
  }

  /**
   * @param params The `WorkCenterService.GetKpisParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `kpiQuery`:
   *
   * - `shiftId`:
   *
   * - `productionOrderId`:
   */
  getKpisResponse(params: WorkCenterService.GetKpisParams): __Observable<__StrictHttpResponse<Array<KpiValue>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.kpiQuery;
    if (params.shiftId != null) __params = __params.set('shiftId', params.shiftId.toString());
    if (params.productionOrderId != null) __params = __params.set('productionOrderId', params.productionOrderId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/WorkCenter/${encodeURIComponent(String(params.workCenterId))}/Kpis`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<KpiValue>>;
      })
    );
  }
  /**
   * @param params The `WorkCenterService.GetKpisParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `kpiQuery`:
   *
   * - `shiftId`:
   *
   * - `productionOrderId`:
   */
  getKpis(params: WorkCenterService.GetKpisParams): __Observable<Array<KpiValue>> {
    return this.getKpisResponse(params).pipe(__map((_r) => _r.body as Array<KpiValue>));
  }

  /**
   * @param params The `WorkCenterService.GetWasteAssignmentsParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `periodStart`:
   *
   * - `periodEnd`:
   */
  getWasteAssignmentsResponse(
    params: WorkCenterService.GetWasteAssignmentsParams
  ): __Observable<__StrictHttpResponse<Array<WasteAssignmentInTime>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.periodStart != null) __params = __params.set('periodStart', params.periodStart.toString());
    if (params.periodEnd != null) __params = __params.set('periodEnd', params.periodEnd.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/WorkCenter/${encodeURIComponent(String(params.workCenterId))}/wasteAssignments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<WasteAssignmentInTime>>;
      })
    );
  }
  /**
   * @param params The `WorkCenterService.GetWasteAssignmentsParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `periodStart`:
   *
   * - `periodEnd`:
   */
  getWasteAssignments(params: WorkCenterService.GetWasteAssignmentsParams): __Observable<Array<WasteAssignmentInTime>> {
    return this.getWasteAssignmentsResponse(params).pipe(__map((_r) => _r.body as Array<WasteAssignmentInTime>));
  }
}

module WorkCenterService {
  /**
   * Parameters for Shutdown
   */
  export interface ShutdownParams {
    workCenterId: number;
    shutdownEntry: ShutdownEntry;
  }

  /**
   * Parameters for ScanContainer
   */
  export interface ScanContainerParams {
    workCenterId: number;
    entry: ScanContainerEntry;
  }

  /**
   * Parameters for GetPeriods
   */
  export interface GetPeriodsParams {
    workCenterId: number;
    periodStart?: string;
    periodEnd?: string;
  }

  /**
   * Parameters for GetKpis
   */
  export interface GetKpisParams {
    workCenterId: number;
    kpiQuery: GetKpisEntry;
    shiftId?: null | number;
    productionOrderId?: null | number;
  }

  /**
   * Parameters for GetWasteAssignments
   */
  export interface GetWasteAssignmentsParams {
    workCenterId: number;
    periodStart?: string;
    periodEnd?: string;
  }
}

export { WorkCenterService };
