/* tslint:disable */
type ManualMachineCheckoutPageMode = 'Simple' | 'MultiUser';
module ManualMachineCheckoutPageMode {
  export const SIMPLE: ManualMachineCheckoutPageMode = 'Simple';
  export const MULTI_USER: ManualMachineCheckoutPageMode = 'MultiUser';
  export function values(): ManualMachineCheckoutPageMode[] {
    return [SIMPLE, MULTI_USER];
  }
}

export { ManualMachineCheckoutPageMode };
