import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lib-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconButtonComponent {
  @Input() public disabled = false;
  @Input() public iconClass: string;
  @Input()
  get iconStyleClass(): string {
    return this._iconStyleClass;
  }

  set iconStyleClass(value: string) {
    this._iconStyleClass = value;
  }

  @Output() public clicked = new EventEmitter<null>();
  public _iconStyleClass: string = 'icon-button';

  public onClick(): void {
    if (this.disabled) {
      return;
    }

    this.clicked.emit();
  }
}
