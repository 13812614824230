/* tslint:disable */
type AssignReasonToDowntimeBehaviourType = 'ShiftReport' | 'ApprovalReport' | 'Task';
module AssignReasonToDowntimeBehaviourType {
  export const SHIFT_REPORT: AssignReasonToDowntimeBehaviourType = 'ShiftReport';
  export const APPROVAL_REPORT: AssignReasonToDowntimeBehaviourType = 'ApprovalReport';
  export const TASK: AssignReasonToDowntimeBehaviourType = 'Task';
  export function values(): AssignReasonToDowntimeBehaviourType[] {
    return [SHIFT_REPORT, APPROVAL_REPORT, TASK];
  }
}

export { AssignReasonToDowntimeBehaviourType };
