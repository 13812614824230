/* tslint:disable */
type KpiQueryMode = 'All' | 'First';
module KpiQueryMode {
  export const ALL: KpiQueryMode = 'All';
  export const FIRST: KpiQueryMode = 'First';
  export function values(): KpiQueryMode[] {
    return [ALL, FIRST];
  }
}

export { KpiQueryMode };
